// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars             as fasFaBars             } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faAngleRight       as fasFaAngleRight       } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAngleLeft        as fasFaAngleLeft        } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faArrowLeft        as fasFaArrowLeft        } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import { faCaretDown        as fasFaCaretDown        } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft        as fasFaCaretLeft        } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight       as fasFaCaretRight       } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion   as fasFaCircleQuestion   } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser       as fasFaCircleUser       } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faCircleXmark      as fasFaCircleXmark      } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faClock            as fasFaClock            } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment          as fasFaComment          } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder           as fasFaFolder           } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMagnifyingGlass  as fasFaMagnifyingGlass  } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faTag              as fasFaTag              } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faEnvelope         as fasFaEnvelope         } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faPhone            as fasFaPhone            } from "@fortawesome/pro-solid-svg-icons/faPhone";


import { faFacebookF        as fabFaFacebookF           } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter          as fabFaTwitter             } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faLinkedin         as fabFaLinkedin            } from "@fortawesome/free-brands-svg-icons/faLinkedin";







/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaCaretDown, fasFaAngleRight, fasFaAngleLeft, fasFaArrowLeft, fasFaCaretLeft, fasFaCaretRight, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaCircleXmark, fasFaComment, fasFaFolder, fasFaMagnifyingGlass, fasFaTag, fasFaEnvelope, fasFaPhone);

/**
 * Add brands icons
 */
library.add(fabFaFacebookF, fabFaTwitter, fabFaLinkedin);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
